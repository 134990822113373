import Vue from 'vue'

import debounce from '@/utils/debounce.js'
// 定义一个名为debounce的指令
export default Vue.directive('debounce', {
    // 绑定的值为el，和binding
    bind(el, binding) {
        let execFunc = ''

        if (binding.value instanceof Array) {
            // 函数传参
            const [func, time = 500] = binding.value
            execFunc = debounce(func, time)
        } else {
            // 函数不传参
            execFunc = debounce(binding.value, 500)
        }
        el.addEventListener('click', execFunc)
    }
})
