//
//
//
//
//
//
//
//
//
//
//
//

import Footer from './Footer'
export default {
    name: 'LoginWrap',

    components: {
        LoginFooter: Footer
    },
    props: {
        showFooter: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {}
    },
    // 生命周期 - 创建完成
    created() {

    },

    // DOM挂载完毕
    mounted() {

    },
    methods: {
        handlerRegister() {
            this.$emit('handlerRegister')
        },

        handlerForgetPassword() {
            this.$emit('handlerForgetPassword')
        }
    }
}
