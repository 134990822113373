//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    FormModel as AForm,
    Input as AInput,
    Divider as ADivider,
    Button as AButton
} from 'ant-design-vue'
import ClickCaptcha from '@/components/ClickCaptcha'
import SendVerifyCode from '@/weights/SendVerifyCode'

import debounce from '@/directive/debounce'
import { VERIFY_CODE_TYPE } from '@/constant/enum'

// 工具类
import { validatePhoneNumber } from '@/utils/validate'

export default {
    name: 'FastLogin',

    components: {
        ClickCaptcha,
        SendVerifyCode,
        AInput,
        ADivider,
        AForm,
        AFormItem: AForm.Item,
        AButton
    },

    directives: {
        debounce
    },
    props: {
        btnLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            verifyCodeObj: VERIFY_CODE_TYPE,
            mobileNum: 'mobileNum',
            rules: {
                mobileNum: [
                    {
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur'
                    }, {
                        validator: validatePhoneNumber,
                        trigger: 'blur'
                    }
                ],
                verifyCode: [
                    {
                        required: true,
                        message: '请输入短信验证码'
                    }, {
                        enum: 'number',
                        max: 8,
                        message: '请输入正确的验证码',
                        trigger: ['blur, trigger']
                    }
                ],
                captcha: [
                    {
                        required: true,
                        validator: (rule, val, cb) => {
                            console.log(val)
                            if (!val) {
                                cb(new Error('请点击验证'))
                            } else {
                                cb()
                            }
                        }
                    }
                ]
            },

            verifyCode: 'verifyCode',
            captcha: 'captcha',

            clickCaptchaListenData: {
                mobileNum: '',
                verifyCode: '',
                captcha: ''
            },

            loading: false
        }
    },
    // 生命周期 - 创建完成
    created() {

    },

    // DOM挂载完毕
    mounted() {

    },
    methods: {
        handlerLogin() {
            this.$refs.form.validate((isValidate, values) => {
                console.log(isValidate, values)
                if (isValidate) {
                    this.$emit('submit', this.clickCaptchaListenData)
                }
            })
        },

        changeClickCaptcha(state) {
            let { captcha } = this
            this.clickCaptchaListenData[captcha] = state

        },

        handlerMobileNormalize(val) {
            this.clickCaptchaListenData.mobileNum = val
            return val
        },


        validateBtn() {
            const { mobileNum } = this
            let result = false
            // this.$refs.ruleForm.validateField
            this.$refs.form.validateField([mobileNum], error => {
                if (!error) {
                    result = true
                }
            })

            return result
        }
    }
}
