//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoginWrap from '@/components/LoginWrap'
import LoginPanel from '@/weights/LoginPanel'
import RightSide from '@/components/RightSide'

// import { LOGIN_PAGE_JUMP_URL } from '@/config'
export default {
    name: 'Login',

    components: {
        LoginPanel,
        LoginWrap,
        RightSide
    },
    data() {
        return {}
    },

    computed: {
        toRedirectPath() {
            return this.$route.query.redirect ? '?redirect=' + this.$route.query.redirect : ''
        }
    },
    // 生命周期 - 创建完成
    created() {

    },

    // DOM挂载完毕
    mounted() {

    },

    methods: {
        handlerRegister() {
            this.$router.replace('/register' + this.toRedirectPath)
        },

        toUnderstandDetail() {
            // this.$router.push({ path: LOGIN_PAGE_JUMP_URL })
        }
    }
}
