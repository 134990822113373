//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    FormModel as AForm,
    Input as AInput,
    Divider as ADivider,
    Button as AButton
} from 'ant-design-vue'

import ClickCaptcha from '@/components/ClickCaptcha'

// 工具类
import { validatePhoneNumber, validatePassword } from '@/utils/validate'

export default {
    name: 'FastLogin',

    components: {
        ClickCaptcha,
        AInput,
        ADivider,
        AInputPassword: AInput.Password,
        AForm,
        AFormItem: AForm.Item,
        AButton
    },
    props: {
        btnLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            form: this.$form.createForm(this),
            mobileNum: 'mobileNum',
            rules: {
                mobileNum: [
                    {
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur'
                    }, {
                        required: true,
                        validator: validatePhoneNumber,
                        trigger: 'blur'
                    }
                ],
                password: [
                    {
                        required: true,
                        message: '请输入密码'
                    }, {
                        required: true,
                        validator: validatePassword,
                        trigger: 'blur'
                    }
                ],
                captcha: [
                    {
                        required: true,
                        validator: (rule, val, cb) => {
                            console.log(val, 'captcha')
                            if (!val) {
                                cb(new Error('请点击验证'))
                            } else {
                                cb()
                            }
                        }
                    }
                ]
            },
            password: 'password',
            captcha: 'captcha',

            clickCaptchaListenData: {
                mobileNum: '',
                password: '',
                captcha: ''
            }
        }
    },
    computed: {
        changeArr() {
            const { mobileNum, password } = this.clickCaptchaListenData
            return {
                mobileNum,
                password
            }
        }
    },
    // 生命周期 - 创建完成
    created() {

    },

    // DOM挂载完毕
    mounted() {

    },
    methods: {
        handlerLogin() {
            this.$refs.form.validate((isValidate, values) => {
                console.log(isValidate, values)
                if (isValidate) {
                    this.$emit('submit', this.clickCaptchaListenData)
                }
            })
        },

        changeCaptcha(state) {
            let { captcha } = this
            console.log(state)
            this.clickCaptchaListenData[captcha] = state
        }
    }
}
