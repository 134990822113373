//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    Button as AButton,
    Icon as AIcon
} from 'ant-design-vue'

export default {
    name: 'LoginPanelFooter',

    components: {
        AButton,
        AIcon
    },
    props: {
        visible: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {}
    },
    // 生命周期 - 创建完成
    created() {

    },

    // DOM挂载完毕
    mounted() {

    },
    methods: {
        handlerRegister() {
            this.$emit('handlerRegister')
        },

        handlerForgetPassword() {
            this.$emit('handlerForgetPassword')
        }
    }
}
