//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    Input as AInput,
    Icon as AIcon,
    Divider as ADivider,
    Tabs as ATabs,
    Button as AButton,
    Row as ARow,
    Col as ACol,
    Form as AForm,
    Alert as AAlert
} from 'ant-design-vue'

import SliderCaptcha from '@/components/SliderCaptcha/SliderCaptchaPanel.vue'
import FastLogin from './FastLogin'
import PasswordLogin from './PasswordLogin'

// 引入API
import passwordLoginService from '@/service/login/passwordLoginService'
import smsLoginRegisterService from '@/service/login/smsLoginRegisterService'
import userFastLoginService from '@/service/login/userFastLoginService'

export default {
    name: 'LoginPanel',

    components: {
        SliderCaptcha,
        AInput,
        AIcon,
        ADivider,
        ATabs,
        ATabPane: ATabs.TabPane,
        AButton,
        AForm,
        AFormItem: AForm.Item,
        ARow,
        ACol,
        AAlert,
        FastLogin,
        PasswordLogin
    },
    props: {
        // 业务类型
        businessType: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        return {
            pwErrorMessage: '',
            fastBtnLoading: false,
            fastLoginrrorMessage: '',
            pwBtnLoading: false
        }
    },
    // 生命周期 - 创建完成
    created() {
    },

    // DOM挂载完毕
    mounted() {

    },

    methods: {
        async handlerFastLogin(data) {
            let { mobileNum: phone, verifyCode: smsCode } = data
            try {
                this.fastBtnLoading = true
                let token = await userFastLoginService({
                    mobile: phone,
                    smsCode
                })
                this.LoginSuccess(token)
            } catch (e) {
                this.fastLoginrrorMessage = e.message
            } finally {
                this.fastBtnLoading = false
            }
        },

        async handlerPwLogin(data) {
            let { mobileNum: phone, password } = data

            try {
                this.pwBtnLoading = true
                let token = await passwordLoginService({
                    phone,
                    password
                })
                this.LoginSuccess(token)
            } catch (e) {
                this.pwErrorMessage = e.message
            } finally {
                this.pwBtnLoading = false
            }
        },

        /**
         * 获取用户信息
         * @param commit
         * @returns {Promise<void>}
         */
        async getUserInfo() {
            await this.$store.dispatch('getUserInfo')
        },

        async LoginSuccess(token) {
            await this.$store.dispatch('setTokenAction', token)
            await this.getUserInfo()
            this.$message.success('登录成功')
            const { businessType } = this
            // 产品详情页登录
            if (businessType === 'productInfo') {
                this.handleProductInfo()
            } else {
                // 普通登录
                this.$router.replace({
                    path: this.$route.query.redirect ? this.$route.query.redirect : '/'
                })
            }
            this.pwErrorMessage = ''
            this.fastLoginrrorMessage = ''
        },
        // 产品详情页登录
        handleProductInfo() {
            this.$emit('success')
        }
    }
}
