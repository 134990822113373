import { axios } from '@/plugins/axios'
import { PASSWORD_LOGIN } from './api'
import { HTTP_ERR_SUCCESS, HTTP_RESULT_FIELD, HTTP_ERR_MESSAGE_FIELD } from '@/constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'
/**
 * 用户名 密码方式登录
 * @returns {Promise<*>}
 */
export default async function passwordLoginService(payload = {}, config = {}) {
    const param = {
        ...PASSWORD_LOGIN,
        ...config,
        params: {
            ...payload
        }
    }
    const { data } = await axios(param)
    if (data.code === HTTP_ERR_SUCCESS) {
        return data[HTTP_RESULT_FIELD]
    } else {
        throw new Error(data[HTTP_ERR_MESSAGE_FIELD] || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
