//
//
//
//
//
//
//
//
//
//
//
//

import SliderCaptcha from './core'

export default {
    name: 'SliderCaptcha',
    props: {
        config: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            sliderCaptcha: null
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            const el = this.$refs.captcha
            const url = require('@/assets/images/captcha/default2x.png')

            if (el) {
                const { config } = this
                const option = {
                    ...config,
                    onSuccess: this.handleSuccess,
                    onFail: this.handleFail,
                    setSrc() {
                        return url
                    }
                }
                this.sliderCaptcha = new SliderCaptcha(el, option)
            }
        },
        handleSuccess() {
            const { sliderCaptcha } = this
            this.$emit('success', {
                sliderCaptcha
            })

        },
        handleFail() {
            const { sliderCaptcha } = this
            this.$emit('fail', {
                sliderCaptcha
            })
        }
    }
}
