//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Modal as AModal } from 'ant-design-vue'
import SliderCaptchaPanel from './SliderCaptchaPanel'

export default {
    name: 'SliderCaptcha',
    components: {
        AModal,
        SliderCaptchaPanel
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        config: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    methods: {
        handleCancel() {
            this.$emit('cancel')
        },
        handleSuccess(data) {
            this.$emit('success', data)
        },
        handleFail(data) {
            this.$emit('fail', data)
        }
    }
}
