//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SliderCaptcha from '@/components/SliderCaptcha'

import {
    Input as AInput,
    Icon as AIcon,
    Divider as ADivider
} from 'ant-design-vue'
export default {
    name: 'ClickCaptcha',

    components: {
        SliderCaptcha,
        AInput,
        AIcon,
        ADivider
    },
    props: {
        // 验证成功 文字提示
        successText: {
            type: String,
            default: '验证成功'
        },

        failText: {
            type: String,
            default: '点击验证'
        },

        // 监听放入数组的数据，如果发生改变重置校验状态
        changeArr: null,

        // 是否显示验证
        showVerify: {
            type: null,
            default: true
        },

        decorator: null

    },
    data() {
        return {
            visibleCaptcha: false,
            checked: false
        }
    },

    watch: {
        checked: {
            handler(newD) {
                this.$emit('change', newD)
            }
            // immediate: true
        },

        changeArr: {
            handler(newD) {
                // console.log('%c reset verify', 'color: lightgreen')
                this.checked = false
            },
            deep: true
        }
    },
    // 生命周期 - 创建完成
    created() {
    },

    // DOM挂载完毕
    mounted() {
    },

    methods: {
        handleSuccess(data) {
            setTimeout(() => {
                this.visibleCaptcha = false
                this.checked = true
            }, 600)
        },

        handleFail(data) {
        },

        triggerBlur(e) {
            e.target.blur()
        }
    }
}
