//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    Input as AInput,
    Icon as AIcon,
    Divider as ADivider,
    Button as AButton
} from 'ant-design-vue'

import debounce from '@/directive/debounce'
import sendCodeService from '@/service/login/sendCodeService'
import { SMS_COUNTDOWN_TIME } from '@/config'
import { VERIFY_CODE_TYPE } from '@/constant/enum'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@/constant/error'

export default {
    name: 'SendVerifyCode',

    components: {
        AInput,
        AIcon,
        ADivider,
        AButton
    },

    directives: {
        debounce
    },
    props: {
        phone: {
            type: [String, Number]
            // required: true
        },

        codeType: {
            type: String,
            default: VERIFY_CODE_TYPE.login
        },

        sendVerify: {
            type: Function,
            default: () => true
        },

        value: [String, Number],

        showIcon: {
            type: Boolean,
            default: false
        },

        maxLength: {
            type: Number,
            default: 8
        }
    },

    data() {
        return {
            verifyCodeBtnTxt: '获取验证码',
            verifyCodeBtnDis: false,
            sendCodeLoading: false
        }
    },

    computed: {
        code: {
            get() {
                return this.value
            },

            set(val) {
                this.$emit('input', val)
            }
        }
    },
    // 生命周期 - 创建完成
    created() {

    },

    // DOM挂载完毕
    mounted() {

    },

    methods: {
        validateBtn() {
            if (this.sendVerify()) {
                this.sendCode({
                    phone: this.phone,
                    type: this.codeType
                })
            }
        },

        async sendCode(data) {
            let { phone, type } = data
            this.sendCodeLoading = true
            try {
                await sendCodeService({
                    phone,
                    verifyType: type
                })
                this.sendCodeLoading = false
                this.$emit('sendSuccess', phone)
                this.$message.success('发送成功')
                this.countDown()
            } catch (e) {
                this.sendCodeLoading = false
                this.$emit('sendFailed', phone)
                this.$message.error(e.message || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
            }
        },

        countDown() {
            let time = SMS_COUNTDOWN_TIME
            let timer = setInterval(() => {
                if (time == 0) {
                    clearInterval(timer)
                    this.verifyCodeBtnDis = false
                    this.verifyCodeBtnTxt = '获取验证码'
                } else {
                    this.verifyCodeBtnDis = true
                    this.verifyCodeBtnTxt = time + '秒后重试'
                    time--
                }
            }, 1000)
        }
    }
}
